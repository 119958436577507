import slugify from 'slugify'
import * as dayjs from 'dayjs'
import deliveryModesMap from '@/data/delivery-modes-map'
import {
  transformLogoEntry,
  transformCatalogContentEntry,
  transformGenericContentPageEntry,
  transformEntryByType
} from '@/transformers/content'
import {
  extractProductPrimaryImage,
  extractProductGalleryImages,
  extractProductResources,
  extractProductVariants,
  extractProductCategory,
  extractCategoriesData,
  extractPromoBanner,
  extractDiscountAmountFromProduct,
  extractDiscountAmountFromCartEntry,
  extractDiscountedProductPrice,
  extractPdpNotices
} from '@/transformers/transformHelpers'

const transformCatalogEntry = ({ value, catalogGroup, contentfulCatalogs, defaultPlpMeta }) => {
  // @TODO cleanup dependencies, return adapted collection
  const catalogTitle = value.split(' > ').slice(-1).pop()
  const catalogSlug = value.split(' > ').map(el => slugify(el.toLowerCase())).join('/')
  const catalogType = catalogGroup.includes('Categories') ? 'category' : 'brand'
  const rootGroup = catalogGroup.split('.lvl')[0]

  const contentEntry = contentfulCatalogs.find(el => el.fields.key?.toLowerCase() === catalogSlug)
  const transformedContent = contentEntry ? transformCatalogContentEntry(contentEntry) : null

  const landingUrl = `/${catalogType}/${catalogSlug}`
  const productsUrl = `/${catalogType}/${catalogSlug}/products`
  const isLanding = !!(transformedContent?.showAs === 'Landing')
  const url = isLanding ? landingUrl : productsUrl
  const title = transformedContent?.title || catalogTitle
  // @TODO exclude last key which is entry title itself, treat deps accordingly
  const parentKeys = catalogSlug
    .split('/')
    .reduce((sum, cur, idx, arr) => {
      const prefix = sum[idx - 1] ? sum[idx - 1] + '/' : ''
      return [...sum, prefix + cur]
    }, [])

  const extractMeta = () => {
    const meta = transformedContent?.meta || defaultPlpMeta
    return {
      key: meta?.key,
      title: meta?.title?.replace('$CATEGORY_NAME', title),
      description: meta?.description?.replace('$CATEGORY_NAME', title)
    }
  }

  return {
    title,
    showMegamenuCarousel: transformedContent?.showMegamenuCarousel || false,
    indexTitle: `'${catalogGroup.replace(/'/g, "\\'")}':'${value.replace(/'/g, "\\'")}'`,
    type: catalogType,
    url: url,
    landingUrl: landingUrl,
    productsUrl: productsUrl,
    key: catalogSlug,
    parentKeys: parentKeys,
    isDecorated: !!transformedContent,
    isLanding: isLanding,
    logo: transformedContent?.logo || transformedContent?.image || 'https://via.placeholder.com/150x150.png',
    description: transformedContent?.description || null,
    originalTitle: value,
    rootGroup: rootGroup,
    showBannerAd: typeof transformedContent?.showBannerAd === 'boolean' ? transformedContent.showBannerAd : true,
    image: transformedContent?.image,
    imageMobile: transformedContent?.imageMobile,
    imageAlt: transformedContent?.imageAlt,
    imageDescription: transformedContent?.imageDescription,
    meta: extractMeta(),
    content: transformedContent ? {
      heroBanner: transformedContent.heroBanner,
      bannerA: transformedContent.bannerA,
      bannerB: transformedContent.bannerB,
      bannerC: transformedContent.bannerC,
      bannerAd: transformedContent.bannerAd
    } : null
  }
}

// algolia || hybris
const transformProductEntry = (entry) => {
  const listPrice = entry.ProductPrice?.listPrice
    ? entry.ProductPrice.listPrice
    : entry.customPrices?.list
      ? entry.customPrices?.list.value
      : entry.priceRange?.maxPrice
        ? entry.priceRange.maxPrice.value
        : null
  const clearancePrice = entry.ProductPrice?.clearancePrice
    ? entry.ProductPrice.clearancePrice
    : entry.customPrices?.clearance
      ? entry.customPrices.clearance.value
      : null
  const employeePrice = entry.customPrices?.employee
    ? entry.customPrices.employee.value
    : null
  const affiliatePrice = entry.ProductPrice?.affiliatePrice
    ? entry.ProductPrice.affiliatePrice
    : entry.customPrices?.affiliate
      ? entry.customPrices.affiliate.value
      : null
  const discountPrice = entry.customDiscountPrices &&
    typeof entry.customDiscountPrices === 'object' &&
    Object.keys(entry.customDiscountPrices)?.length
    ? Math.min(...Object.values(entry.customDiscountPrices).map(el => el.value).filter(el => el))
    : null

  const availablePrices = [listPrice, clearancePrice, employeePrice, affiliatePrice, discountPrice].filter(el => el)
  const minPrice = availablePrices.length ? Math.min(...availablePrices) : null

  return {
    title: entry.title || entry.name,
    descriptionShort: entry['Description - Short'] || entry.summary,
    descriptionLong: entry['Description - Short'] || entry.description,
    collectionName: entry['Collection Name'] || entry.collectionName,
    isPurchasable: entry.purchasable,
    isPublicStore: entry.publicStore || false,
    isEmployeeStore: entry.employeeStore === 'Yes' || false,
    isAffiliateStore: entry.affiliateStore === 'Yes' || false,
    image: extractProductPrimaryImage(entry),
    primaryImageStatus: entry.primaryImageStatus || null,
    images: extractProductGalleryImages(entry),
    asset3d: entry.dataSheets?.find(el => el.description === '3D Product View')?.url?.replace('/hubbellcommercewebservices/v2', ''),
    logoImage: entry.dataSheets?.length
      ? entry.dataSheets.find(el => el.type === 'PRODUCT_LOGO')?.url?.replace('/hubbellcommercewebservices/v2', '')
      : null,
    brand: entry.Brand || entry.brand || null,
    link: '/product/' + (entry.objectID ? entry.objectID : entry.sku),
    sku: entry.objectID || entry.sku,
    baseProduct: entry['BaseProduct ID'] || entry.baseProduct,
    catalogId: entry['Catalog Number'] || entry.catalogNumber,
    energyGuideLink: entry.dataSheets?.length
      ? entry.dataSheets.find(el => el.type === 'ENERGY_GUIDE')?.url?.replace('/hubbellcommercewebservices/v2', '')
      : null,
    prop65Link: entry.dataSheets?.some(el => el.type === 'CUSTOMER_NOTICES')
      ? 'https://hubbellcdn.com/CustomerNotices/Prop65.pdf'
      : null,
    resources: entry.dataSheets?.length
      ? extractProductResources(entry.dataSheets)
      : null,
    minOrderQty: entry['Minimum Order Quantity']
      ? parseInt(entry['Minimum Order Quantity']) || 1
      : entry.minOrderQty || 1,
    listPrice,
    clearancePrice,
    employeePrice,
    affiliatePrice,
    discountPrice,
    minPrice,
    hasPrice: !!(listPrice || clearancePrice || employeePrice || affiliatePrice || discountPrice),
    classifications: entry.webStoreClassifications && entry.webStoreClassifications.length
      ? entry.webStoreClassifications.filter(el => el.code !== 'AG_OHWSpecifications_Logistics').map(el => ({
        title: el.name,
        list: el.features?.map(el => ({
          name: el.name,
          value: el.featureValues.map(el => el?.value).join(', ')
        }))
      })) : null,
    upcCode: entry.webStoreClassifications && entry.webStoreClassifications.length
      ? entry.webStoreClassifications
        .find(el => el.code === 'AG_OHWSpecifications_General')?.features
        .find(el => el.name === 'UPC')?.featureValues?.[0]?.value
      : null,
    productReferences: entry.productReferences?.length
      ? entry.productReferences
        .filter(el => el.target?.id && (el.target?.sku !== entry.sku) && el.referenceType === 'SIMILAR')
        .map(el => transformProductEntry(el.target))
      : null,
    comparableProducts: entry.productReferences
      ?.filter(el => el.target?.id && el.referenceType === 'PDPCOMPARISON')
      .map(el => transformProductEntry(el.target)) || null,
    productVariants: extractProductVariants(entry),
    featuresList: entry.featuresBullets || null,
    featuresSpecial: entry.specialFeatures || null,
    showInventory: entry.webShowInventory || false,
    promoBanner: extractPromoBanner(entry.potentialPromotions),
    pdpNotices: extractPdpNotices(entry),
    discontinued: entry['Discontinued - Web'] === 'Yes' ? true : false || entry.discontinuedWeb || false
  }
}

// @TODO remove hasFinalizedPrices flag on proper employee price fetch
const transformProductSearchEntry = ({ entry, categories, hasFinalizedPrices }) => {
  const productCategories = entry.Categories?.map(facet => {
    const catLevel = Object.keys(facet).length
    const nestedCategories = []

    for (let i = 0; i < catLevel; i++) {
      const category = categories.find(el => el.originalTitle === facet['lvl' + i].trim())
      if (category) nestedCategories.push(category)
    }

    return nestedCategories
  })
  const product = transformProductEntry(entry)
  product.groupedCategories = productCategories && productCategories.length ? productCategories : null
  product.hasFinalizedPrices = hasFinalizedPrices

  return product
}

const transformCartEntry = (entry, options = { showProductsPriceChange: true, entriesDeep: true }) => {
  const customerUid = entry.user.email
  const rawEntries = options.entriesDeep
    ? entry.entries?.map(el => el.entries).flat()
    : entry.entries
  const entries = (rawEntries ?? []).map(el => transformCartItemEntry(el, entry)).sort((a, b) => a.number - b.number)
  const extractAppliedPromotions = (entry) => {
    const appliedPromotionsBlacklist = ['ground_shipping_flat_fee', 'ground_shipping_flat_fee_ca']
    let appliedPromotions = []
    const promotionsList = Object.values(entry.promotions ?? {})

    promotionsList.forEach(promo => {
      if (promo.coupons?.length) {
        promo.coupons.forEach(coupon => {
          appliedPromotions.push({
            code: coupon,
            description: entry.invalidPromotions ? entry.invalidPromotionsMessage : promo.message,
            discount: entry.invalidPromotions ? 0 : promo.totalDiscount,
            isRemovable: true
          })
        })
      } else {
        appliedPromotions.push({
          code: promo.code,
          description: promo.message,
          discount: promo.totalDiscount,
          isRemovable: false
        })
      }
    })

    const isVouchersWithNoDiscountApplied = appliedPromotions.filter(el => el.isRemovable).length < entry.appliedVouchers.length
    if (isVouchersWithNoDiscountApplied) {
      const appliedPromotionCodes = appliedPromotions.filter(el => el.isRemovable).map(el => el.code)
      entry.appliedVouchers
        .filter(el => !appliedPromotionCodes.includes(el.voucherCode))
        .forEach(el => {
          appliedPromotions.push({
            code: el.voucherCode,
            description: entry.invalidPromotions ? entry.invalidPromotionsMessage : el.description,
            discount: 0,
            isRemovable: true
          })
        })
    }
    appliedPromotions = appliedPromotions.filter(el => !appliedPromotionsBlacklist.includes(el.code))

    return appliedPromotions.length ? appliedPromotions.sort((a, b) => b.isRemovable - a.isRemovable) : null
  }

  return {
    id: entry.id,
    guid: entry.guid,
    baseStoreId: entry.store,
    entries,
    subTotal: entry.subTotal?.value,
    subtotalWithoutDiscount: entry.subtotalWithoutDiscount?.value,
    totalPrice: entry.totalPrice?.value,
    totalItems: entry.totalItems,
    totalDiscounts: entry.totalDiscounts?.value,
    totalTax: entry.totalTax?.value,
    deliveryMode: entry.deliveryMode && Object.keys(entry.deliveryMode).length
      ? transformDeliveryModeEntry(entry.deliveryMode) : null,
    shippingAddress: entry.deliveryAddress && Object.keys(entry.deliveryAddress).length
      ? transformAddressEntry(entry.deliveryAddress) : null,
    customerUid,
    isGuestCart: entry.user.uid?.startsWith('guest_') || false,
    paymentDetails: entry.paymentInfo && entry.paymentInfo.cardNumber ? transformPaymentDetails(entry) : null,
    potentialOrderPromotions: entry.potentialOrderPromotions?.length
      ? entry.potentialOrderPromotions.map(el => ({
        code: el?.promotion.code,
        description: el.description
      }))
      : null,
    potentialProductPromotions: entry.potentialProductPromotions?.length
      ? entry.potentialProductPromotions.map(el => ({
        code: el?.promotion.code,
        description: el.description
      }))
      : null,
    appliedPromotions: extractAppliedPromotions(entry),
    appliedVouchers: entry.appliedVouchers?.length
      ? entry.appliedVouchers.map(el => ({
        code: el.voucherCode,
        description: el.description,
        discount: 0,
        isRemovable: true
      }))
      : null,
    isSomeProductPriceChanged: entries.some(entry => entry.oldPrice !== entry.adjustedPrice),
    showProductsPriceChange: options.showProductsPriceChange,
    isInvalidPromotionsApplied: entry.invalidPromotions,
    invalidPromotionsMessage: entry.invalidPromotionsMessage
  }
}

const transformPaymentDetails = (entry) => ({
  paymentType: 'creditCard',
  billingAddress: transformAddressEntry(entry.paymentInfo.billingAddress),
  accountHolderName: entry.paymentInfo.accountHolderName,
  cardNumber: entry.paymentInfo.cardNumber,
  cardTypeCode: entry.paymentInfo.cardType.code,
  expirationDate: entry.paymentInfo.expiryMonth + '/' + entry.paymentInfo.expiryYear
})

const transformCartItemEntry = (entry, cartEntry) => {
  const extractProductDiscounts = (entry) => {
    const entryDiscounts = []
    entry.discounts.forEach(el => {
      const discount = cartEntry.promotions[el.promotionCode]
      if (discount?.isProductPromotion) {
        entryDiscounts.push({
          code: discount.code,
          description: discount.message
        })
      }
    })
    return entryDiscounts
  }
  const extractExtraProperties = (entry) => {
    if (!Object.keys(entry.extraProperties)?.length) return null
    return entry.extraProperties
  }
  return {
    number: entry.entryNumber,
    quantity: entry.quantity,
    oldPrice: entry.oldPrice?.value,
    adjustedPrice: entry.adjustedPrice?.value,
    totalPriceWithoutDiscount: entry.totalPriceWithoutDiscount?.value,
    totalPrice: entry.totalPrice?.value,
    discounts: entry.discounts?.length ? extractProductDiscounts(entry) : null,
    allDiscounts: entry.discounts?.length
      ? entry.discounts.map(item => ({
        code: item.promotionCode,
        description: ''
      }))
      : null,
    product: transformProductEntry(entry.productDetails),
    extraProperties: extractExtraProperties(entry)
  }
}

// ~ OrderHistoryEntry || OrderDetailsEntry
const transformOrderEntry = (entry) => ({
  id: entry.code,
  link: `/account/order-history/${entry.code}`,
  created: entry.created || entry.placed,
  deliveryCost: entry.deliveryCost?.value,
  totalItems: entry.totalItems,
  totalPrice: entry.totalPrice?.value,
  totalPriceWithTax: entry.totalPriceWithTax?.value || entry.totalPrice?.value,
  subTotal: entry.subTotal?.value,
  totalTax: entry.totalTax?.value,
  totalDiscounts: entry.totalDiscounts?.value,
  customerUid: entry.user.email,
  status: entry.status,
  paymentDetails: entry.paymentInfo?.cardNumber ? transformPaymentDetails(entry) : null,
  shippingAddress: entry.deliveryAddress && Object.keys(entry.deliveryAddress).length
    ? transformAddressEntry(entry.deliveryAddress) : null,
  deliveryMode: entry.deliveryMode && Object.keys(entry.deliveryMode).length
    ? transformDeliveryModeEntry(entry.deliveryMode) : null,
  entries: entry.entries && entry.entries.length
    ? entry.entries.map(el => el.entries).flat().map(transformOrderItemEntry).sort((a, b) => a.number - b.number)
    : null
})

const transformOrderItemEntry = (entry) => ({
  number: entry.entryNumber,
  quantity: entry.quantity,
  totalPrice: entry.totalPrice?.value,
  totalPriceWithoutDiscount: entry.totalPriceWithoutDiscount?.value
    ? entry.totalPriceWithoutDiscount?.value
    : +(entry.quantity * entry.basePrice?.value).toFixed(2),
  basePrice: entry.basePrice?.value,
  adjustedPrice: entry.adjustedPrice?.value,
  lineStatus: entry.lineStatus,
  shipDates: entry.shipDates && Object.keys(entry.shipDates).length
    ? entry.shipDates
    : null,
  trackingItems: entry.trackingItems && entry.trackingItems.length
    ? entry.trackingItems.map(el => ({
      shippedQuantity: el.shippedQuantity,
      trackingId: el.trackingId
    }))
    : null,
  product: transformProductEntry(entry.product)
})

const transformProductsListItemEntry = (entry) => {
  const productData = transformProductEntry(entry.product)
  const quantity = entry.product?.minOrderQty > entry.quantity
    ? entry.product?.minOrderQty
    : entry.quantity
  const totalPrice = (quantity * productData.minPrice).toFixed(2)
  return {
    number: entry.product.code,
    quantity,
    totalPrice,
    product: productData
  }
}

const transformUserEntry = (entry) => {
  const extractAffiliate = (entry) => {
    if (!entry.affiliate) return null
    const currentDate = dayjs()
    const affiliateStartDate = entry.affiliateStartDate ? dayjs(entry.affiliateStartDate) : null
    const affiliateEndDate = entry.affiliateEndDate ? dayjs(entry.affiliateEndDate) : null
    function checkAffiliateState () {
      if (!affiliateStartDate && !affiliateEndDate) return true
      if (affiliateStartDate && affiliateEndDate) return currentDate.diff(affiliateStartDate) > 0 && currentDate.diff(affiliateEndDate) < 0
      if (affiliateStartDate) return currentDate.diff(affiliateStartDate) > 0
      if (affiliateEndDate) return currentDate.diff(affiliateEndDate) < 0
    }
    const isAffiliateActive = checkAffiliateState()
    return isAffiliateActive ? 'affiliate' : null
  }
  const extractUserGroups = (entry) => {
    const employeeUser = entry.isEmployee ? 'employee' : null
    const affiliateUser = extractAffiliate(entry)
    const publicUser = employeeUser || affiliateUser ? null : 'public'
    return [employeeUser, affiliateUser, publicUser].filter(el => el)
  }

  return {
    firstName: entry.firstName,
    lastName: entry.lastName,
    fullName: entry.name,
    uid: entry.uid,
    customerId: entry.customerId || entry.uid,
    groups: extractUserGroups(entry),
    productInterests: entry.productInterests,
    customerRole: entry.customerRole,
    title: entry.title || null,
    titleCode: entry.titleCode || null,
    companyName: entry.companyName,
    contactNumber: entry.contactNumber,
    employeeInviteFriendCode: entry.individualFriendsAndFamilyCode || '',
    friendsAndFamilyCode: entry.usedIndividualFriendsAndFamilyCode || ''
  }
}

const transformAddressEntry = (entry) => ({
  id: entry.id,
  defaultAddress: entry.defaultAddress,
  title: entry.title || null,
  titleCode: entry.titleCode || null,
  firstName: entry.firstName || null,
  lastName: entry.lastName || null,
  companyName: entry.companyName || null,
  line1: entry.line1 || null,
  line2: entry.line2 || null,
  country: entry.country || null,
  town: entry.town || null,
  region: entry.region
    ? {
      countryIso: entry.region.countryIso,
      isocode: entry.region.isocode,
      name: entry.region.name
    }
    : null,
  postalCode: entry.postalCode || null,
  phone: entry.phone || null
})

const transformDeliveryModeEntry = (entry) => ({
  code: entry.code,
  title: entry.name,
  price: entry.deliveryCost?.value,
  description: entry.description,
  sortOrder: deliveryModesMap[entry.code]?.sortOrder,
  promotionDescription: entry.promotionDescription || null
})

export {
  transformLogoEntry,
  transformCatalogContentEntry,
  transformEntryByType,
  transformGenericContentPageEntry,
  transformCatalogEntry,
  transformProductEntry,
  transformProductSearchEntry,
  transformCartEntry,
  transformCartItemEntry,
  transformOrderEntry,
  transformProductsListItemEntry,
  transformUserEntry,
  transformAddressEntry,
  transformDeliveryModeEntry,
  extractProductCategory,
  extractCategoriesData,
  extractDiscountAmountFromProduct,
  extractDiscountAmountFromCartEntry,
  extractDiscountedProductPrice
}
