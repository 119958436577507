<template>
  <div id="app">
    <ErrorOverlay v-if="showAppError">
      <div slot="body" v-html="appErrorMessage"></div>
    </ErrorOverlay>
    <template v-else-if="isInitialized">
      <Header ref="header" />
      <div
        class="content"
        id="content"
        :style="{ 'margin-top': `${headerHeight}px` }"
      >
        <div class="content-inner">
          <HintBar
            v-if="!isAlertHintBarViewed && showAlertHintBar && alertHintBar"
            :showIcon="!!alertHintBar.icon"
            class="hint-bar--plain"
            :hintData="alertHintBar"
            :closable="true"
            @close="toggleAlertHintBarViewedState(true)"
          />
          <router-view :key="$route.path">
            <crumbs slot="crumbs" :crumbsData="crumbsData" />
          </router-view>
        </div>
        <ScrollTop />
      </div>
      <Footer />
    </template>
    <loader v-else />
  </div>
</template>

<script>
import Crumbs from '@/components/Crumbs'
import ErrorOverlay from '@/components/ErrorOverlay'
import Footer from '@/components/Footer'
import Header from '@/components/Header'
import ScrollTop from '@/components/ScrollTop'
import {
  authenticateUser,
  getCurrentURLState,
  saveAccessToken,
  validateOrGetAccessToken
} from '@/services/auth'
import eventBusService from '@/services/event-bus'
import settings from '@/settings'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

const RESET_PASSWORD_ERROR_CODE = 'AADB2C90118'

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    Crumbs,
    ErrorOverlay,
    ScrollTop,
    HintBar: () => import('@/components/HintBar')
  },
  computed: {
    ...mapState('user', ['userData']),
    ...mapState('crumbs', ['crumbsData']),
    ...mapState('app-error', ['showAppError', 'appErrorMessage']),
    ...mapGetters('user', ['isGuest']),
    ...mapState('content', [
      'alertHintBar',
      'showAlertHintBar',
      'isAlertHintBarViewed'
    ])
  },
  methods: {
    ...mapActions('app', ['setAppData', 'doLogin', 'doLogout', 'doResetPassword']),
    ...mapActions('user', ['resetUserData']),
    ...mapMutations('content', {
      toggleAlertHintBarViewedState: 'TOGGLE_ALERT_HINT_BAR_VIEWED_STATE'
    }),
    parseUrlParams () {
      const queryParams = new URLSearchParams(window.location.search)
      const queryParamsHash = new URLSearchParams(window.location.hash)
      const isPostLogin = !!queryParamsHash.get('id_token')
      const isResetPasswordInitiated = !!queryParamsHash.get('error_description')?.includes(RESET_PASSWORD_ERROR_CODE)

      // @TODO refactor whitelist filters
      const commonParams = [
        settings.app.queryTokens.openTrackOrderOnInitToken,
        settings.app.queryTokens.productsToCompare,
        settings.app.queryTokens.switchSite,
        settings.app.queryTokens.forceLogin,
        settings.app.queryTokens.orderEmail
      ]
      const searchParams = ['query', 'facets', 'page', 'sorting']
      const resetPasswordParams = [
        settings.app.queryTokens.resetPasswordInitiatedToken,
        settings.app.queryTokens.resetPasswordCanceledToken
      ]
      const analyticsParams = [
        'utm_source',
        'utm_medium',
        'utm_campaign',
        'utm_term',
        'utm_content',
        'gclid'
      ]
      const previewParams = ['preview']
      const queryParamsWhitelist = [
        ...searchParams,
        ...commonParams,
        ...resetPasswordParams,
        ...analyticsParams,
        ...previewParams
      ]

      const allowedQueryParams = {}
      queryParams.forEach((value, key) => {
        if (queryParamsWhitelist.includes(key)) allowedQueryParams[key] = value
      })

      const routeData = {
        path: window.location.pathname,
        query: allowedQueryParams,
        hash: window.location.hash
      }

      this.$router.replace(routeData)

      return {
        isPostLogin,
        isResetPasswordInitiated
      }
    },
    async onPostLogin () {
      const query = new URLSearchParams(window.location.hash)

      try {
        const { valid, token } = await authenticateUser(query.get('id_token'))
        if (!valid) {
          this.$router.replace('/')
          return
        }

        saveAccessToken(token)
      } catch (error) {
        console.error('Error:', error)
        this.$router.replace('/')
      }
    }
  },
  async created () {
    const { isPostLogin, isResetPasswordInitiated } = this.parseUrlParams()

    if (isResetPasswordInitiated) {
      try {
        await this.doResetPassword()
      } catch (error) {
        console.error('Error:', error)
      }
    }

    isPostLogin ? await this.onPostLogin() : await validateOrGetAccessToken()

    const bootData = {
      sessionType:
        isPostLogin || !this.isGuest
          ? 'user'
          : settings.app.session.forceLogin
            ? 'force-login'
            : 'anonymous'
    }

    await new Promise((resolve, reject) =>
      this.setAppData({ resolve, reject, bootData })
    ).catch(async (error) => {
      if (window.localStorage.getItem('hubbellForceReloadOnError')) {
        window.localStorage.clear()
        this.doLogout()
        throw error
      } else {
        window.localStorage.clear()
        throw error
      }
    })

    window.localStorage.setItem('hubbellForceReloadOnError', true)

    const queryParams = new URLSearchParams(window.location.search)
    const forceLoginParam = queryParams.get(
      settings.app.queryTokens.forceLogin
    )
    if (forceLoginParam && this.isGuest) {
      this.doLogin()
    } else {
      this.isInitialized = true
      this.$nextTick(function () {
        this.headerHeight = this.$refs.header?.$el?.clientHeight
      })
    }

    if (isPostLogin) {
      const state = getCurrentURLState() ?? { path: '/' }
      this.$router.replace(state)
    }
  },
  beforeDestroy () {
    eventBusService.$off('auth.notAuthorized')
  },
  data () {
    return {
      headerHeight: 0,
      isInitialized: false
    }
  }
}
</script>
