import * as FullStory from '@fullstory/browser'
import Vue from 'vue'
import store from '@/store/index'
import settings from '@/settings'

export default {
  init () {
    if (settings.services.fullStory.enabled && settings.services.fullStory.orgId) {
      // feedback plugin has fullstory as dependency
      // @TODO decouple feedback and fullstory
      const feedbackScript = document.createElement('script')
      feedbackScript.src = 'https://hubbellcdn.com/ohwassets/Hubbell/feedbackjs/feedback.js'
      feedbackScript.async = false
      feedbackScript.defer = true
      feedbackScript.type = 'text/javascript'
      document.head.appendChild(feedbackScript)

      const storeFeedbackScript = document.createElement('script')
      storeFeedbackScript.src = 'https://hubbellcdn.com/ohwassets/Hubbell/feedbackjs/storefeedback.js'
      storeFeedbackScript.async = false
      storeFeedbackScript.defer = true
      storeFeedbackScript.type = 'text/javascript'
      document.head.appendChild(storeFeedbackScript)

      const storeFeedbackStyles = document.createElement('link')
      storeFeedbackStyles.href = 'https://hubbellcdn.s3.amazonaws.com/ohwassets/Hubbell/feedbackjs/feedback.css'
      storeFeedbackStyles.rel = 'stylesheet'
      storeFeedbackStyles.type = 'text/css'
      storeFeedbackStyles.media = 'print'
      storeFeedbackStyles.onload = () => {
        storeFeedbackStyles.media = 'all'
      }
      document.head.appendChild(storeFeedbackStyles)

      storeFeedbackScript.onload = storeFeedbackScript.onerror = () => {
        FullStory.init({ orgId: settings.services.fullStory.orgId })
        store.commit('fullstory/SET_SCRIPT_LOADED', true)
        Vue.prototype.$FullStory = FullStory
      }
    }
  },
  identify ({ uid, displayName, email }) {
    if (settings.services.fullStory.enabled && settings.services.fullStory.orgId) {
      FullStory.identify(uid, {
        displayName,
        email
      })
    }
  },
  orderComplete ({ orderId, products }) {
    if (settings.services.fullStory.enabled && settings.services.fullStory.orgId) {
      FullStory.event('Order Completed', {
        orderId_str: orderId,
        products
      })
    }
  }
}
