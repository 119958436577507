export default {
  app: {
    version: process.env.VUE_APP_VERSION,
    env: process.env.VUE_APP_ENV,
    country: process.env.VUE_APP_COUNTRY,
    currency: process.env.VUE_APP_CURRENCY,
    store: process.env.VUE_APP_STORE,
    title: {
      defaultTitle: process.env.VUE_APP_COUNTRY === 'CA' ? 'Hubbell Canada Store' : 'Hubbell Store',
      separator: ' | '
    },
    queryTokens: {
      openTrackOrderOnInitToken: 'show-track-order',
      resetPasswordInitiatedToken: 'password-reset-initiated',
      resetPasswordCanceledToken: 'password-reset-canceled',
      productsToCompare: 'products-to-compare',
      switchSite: 'switch-site',
      forceLogin: 'force-login',
      orderEmail: 'order-email'
    },
    session: {
      forceLogin: process.env.VUE_APP_SESSION_FORCE_LOGIN.toLowerCase() === 'true'
    }
  },
  services: {
    auth: {
      oAuthLoginURL: process.env.VUE_APP_OAUTH_LOGIN
    },
    api: {
      apiKey: process.env.VUE_APP_API_KEY,
      baseUrl: process.env.VUE_APP_API_BASEURL,
      azureLoginUrl: process.env.VUE_APP_AZURE_LOGIN,
      azureLoginOktaUrl: process.env.VUE_APP_AZURE_LOGIN_OKTA,
      azureSignupUrl: process.env.VUE_APP_AZURE_SIGN_UP,
      azureLoginPath: '/api-proxy/oauth2/authorization/azad',
      azureResetPasswordUrl: process.env.VUE_APP_AZURE_RESET_PASSWORD
    },
    algolia: {
      appId: process.env.VUE_APP_ALGOLIA_APPID,
      accessToken: process.env.VUE_APP_ALGOLIA_ACCESS_TOKEN,
      analyticsEnabled: process.env.VUE_APP_ALGOLIA_ANALYTICS_ENABLED.toLowerCase() === 'true',
      recommendationsEnabled: process.env.VUE_APP_ALGOLIA_RECOMMENDATIONS_ENABLED.toLowerCase() === 'true',
      productIndexRelevance: process.env.VUE_APP_ALGOLIA_PRODUCT_RELEVANCE_INDEX,
      productIndexPopularity: process.env.VUE_APP_ALGOLIA_PRODUCT_POPULARITY_INDEX,
      productIndexPrice: process.env.VUE_APP_ALGOLIA_PRODUCT_PRICE_INDEX,
      productIndexPriceDesc: process.env.VUE_APP_ALGOLIA_PRODUCT_PRICE_DESC_INDEX,
      productIndexSuggestions: process.env.VUE_APP_ALGOLIA_PRODUCT_SUGGESTIONS_INDEX,
      searchApp: {
        appId: process.env.VUE_APP_ALGOLIA_SEARCH_APP_APPID,
        accessToken: process.env.VUE_APP_ALGOLIA_SEARCH_APP_ACCESS_TOKEN,
        productIndexRelevance: process.env.VUE_APP_ALGOLIA_SEARCH_APP_PRODUCT_RELEVANCE_INDEX
      }
    },
    contentful: {
      space: {
        id: process.env.VUE_APP_CONTENTFUL_SPACE_ID,
        name: process.env.VUE_APP_CONTENTFUL_SPACE_NAME,
        environment: process.env.VUE_APP_CONTENTFUL_SPACE_ENV
      },
      accessToken: process.env.VUE_APP_CONTENTFUL_ACCESS_TOKEN,
      host: process.env.VUE_APP_CONTENTFUL_HOST,
      previewAccessToken: process.env.VUE_APP_CONTENTFUL_PREVIEW_TOKEN,
      previewHost: process.env.VUE_APP_CONTENTFUL_PREVIEW_HOST
    },
    fullStory: {
      enabled: process.env.VUE_APP_FULLSTORY_ENABLED.toLowerCase() === 'true',
      orgId: process.env.VUE_APP_FULLSTORY_ORG_ID
    },
    analytics: {
      google: {
        enabled: process.env.VUE_APP_GOOGLE_GTM_ENABLED.toLowerCase() === 'true',
        accessToken: process.env.VUE_APP_GOOGLE_GTM_ACCESS_TOKEN,
        accessTokenCA: process.env.VUE_APP_GOOGLE_GTM_ACCESS_TOKEN_CA
      },
      hubspotScript: {
        enabled: process.env.VUE_APP_HUBSPOT_SCRIPT_ENABLED.toLowerCase() === 'true',
        id: process.env.VUE_APP_HUBSPOT_SCRIPT_ID
      }
    },
    oneTrust: {
      enabled: process.env.VUE_APP_ONETRUST_ENABLED.toLowerCase() === 'true'
    }
  },
  environmentsBaseUrl: {
    dev: {
      US: 'https://store-dev.hubbell.com',
      CA: 'https://store-dev.hubbell.ca'
    },
    qa: {
      US: 'https://store-qa.hubbell.com',
      CA: 'https://store-qa.hubbell.ca'
    },
    stage: {
      US: 'https://store-stage.hubbell.com',
      CA: 'https://store-stage.hubbell.ca'
    },
    prod: {
      US: 'https://store.hubbell.com',
      CA: 'https://store.hubbell.ca'
    }
  },
  brandNamesByCountry: {
    US: 'hubbell',
    CA: 'hubbellcanada'
  },
  groundDeliveryModeCodeByCountry: {
    US: 'WOB',
    CA: 'WOB_CA'
  },
  willCallDeliveryModeCodeByCountry: {
    US: 'CPU',
    CA: 'CPU'
  }
}
