import settings from '@/settings'

export const detectIE = () => {
  const windowNavigator = window.navigator.userAgent.toLowerCase()
  return (/trident/gi).test(windowNavigator) || (/msie/gi).test(windowNavigator)
}

export const detectSafari = () => {
  const windowNavigator = window.navigator.userAgent.toLowerCase()
  return (/^((?!chrome|android).)*safari/gi).test(windowNavigator)
}

export const detectUserAgentBot = () => {
  const windowNavigator = window.navigator.userAgent.toLowerCase()
  return (/bot|googlebot|crawler|spider|robot|crawling/i).test(windowNavigator)
}

export const parseEncodedString = (encodedString) => {
  const el = document.createElement('span')
  el.innerHTML = encodedString
  return el.innerText
}

export const getSortingKeys = (items) => {
  return items.map(el => el.fields.key)
    .reduce((sum, cur, idx) => {
      sum[cur] = idx
      return sum
    }, {})
}

export const setWindowWebpBrowserSupportFlag = () => {
  const img = new Image()
  img.onload = function () {
    var result = (img.width > 0) && (img.height > 0)
    window.isWebpSupported = result
  }
  img.onerror = function () {
    window.isWebpSupported = false
  }
  img.src = 'data:image/webp;base64,UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA'
}

export const checkIsElementInView = (el, percentVisible = 80) => {
  const rect = el?.getBoundingClientRect()
  if (!rect) return false
  const windowHeight = (window.innerHeight || document.documentElement.clientHeight)

  return !(
    Math.floor(100 - (((rect.top >= 0 ? 0 : rect.top) / +-rect.height) * 100)) < percentVisible ||
    Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) < percentVisible
  )
}

export const constructExternalPDPLink = (sku) => {
  return `https://www.hubbell.com/${settings.brandNamesByCountry[settings.app.country]}/en/p/${sku}`
}
